import { Suspense, useState } from "react";
import { motion, MotionConfig, useMotionValue } from "framer-motion";
import { Shapes } from "../components/spec/Shapes";
import { transition } from "../components/spec/Settings";
import useMeasure from "react-use-measure";
import styled from "styled-components";

const Page = styled.div`
    width: 100vw;
    height: auto;
    scroll-snap-align: start;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 2rem;
    text-align: center;
`

const Title = styled.h1`
  color : ${ props => props.theme.colors.primary };
	text-decoration: underline;
	font-size: clamp(2rem, 3.5vw, 3rem); 
	font-family: 'RethinkSans', 'Courier New', Courier, monospace;
`

const Contacts = () => {
  const [ref, bounds] = useMeasure({ scroll: false });
  const [isPhoneHover, setIsPhoneHover] = useState(false);
  const [PhoneText, setPhoneText] = useState('Téléphone');
  const [isMailHover, setIsMailHover] = useState(false);
  const [MailText, setMailText] = useState('Mail');
  const [isDiscordHover, setIsDiscordHover] = useState(false);
  const [DiscordText, setDiscordText] = useState('Discord');
  const [isPress, setIsPress] = useState(false);
  const mouseX = useMotionValue(0);
  const mouseY = useMotionValue(0);

  const resetMousePosition = () => {
    mouseX.set(0);
    mouseY.set(0);
  };

  return (
    <Page>
      <Title>Contacts</Title>
      <MotionConfig transition={transition}>
      <motion.button
        ref={ref}
        initial={false}
        animate={isPhoneHover ? "hover" : "rest"}
        whileTap="press"
        variants={{
          rest: { scale: 1 },
          hover: { scale: 1.5 },
          press: { scale: 1.4 }
        }}
        onHoverStart={() => {
          resetMousePosition();
          setIsPhoneHover(true);
          setPhoneText('07-60-11-93-14');
        }}
        onHoverEnd={() => {
          resetMousePosition();
          setIsPhoneHover(false);
          setPhoneText('Téléphone');
        }}
        onTapStart={() => setIsPress(true)}
        onTap={() => setIsPress(false)}
        onTapCancel={() => setIsPress(false)}
        onPointerMove={(e) => {
          mouseX.set(e.clientX - bounds.x - bounds.width / 2);
          mouseY.set(e.clientY - bounds.y - bounds.height / 2);
        }}
      >
        <motion.div
          className="shapes"
          variants={{
            rest: { opacity: 0 },
            hover: { opacity: 1 }
          }}
        >
          <div className="pink blush" />
          <div className="blue blush" />
          <div className="container">
            <Suspense fallback={null}>
              <Shapes
                isHover={isPhoneHover}
                isPress={isPress}
                mouseX={mouseX}
                mouseY={mouseY}
              />
            </Suspense>
          </div>
        </motion.div>
        <motion.div
          variants={{ hover: { scale: 0.85 }, press: { scale: 1.1 } }}
          className="label"
        >
          {PhoneText}
        </motion.div>
      </motion.button>
      <motion.button
        ref={ref}
        initial={false}
        animate={isMailHover ? "hover" : "rest"}
        whileTap="press"
        variants={{
          rest: { scale: 1 },
          hover: { scale: 1.5 },
          press: { scale: 1.4 }
        }}
        onHoverStart={() => {
          resetMousePosition();
          setIsMailHover(true);
          setMailText("carlameline@gmail.com");
        }}
        onHoverEnd={() => {
          resetMousePosition();
          setIsMailHover(false);
          setMailText("Mail");
        }}
        onTapStart={() => setIsPress(true)}
        onTap={() => setIsPress(false)}
        onTapCancel={() => setIsPress(false)}
        onPointerMove={(e) => {
          mouseX.set(e.clientX - bounds.x - bounds.width / 2);
          mouseY.set(e.clientY - bounds.y - bounds.height / 2);
        }}
      >
        <motion.div
          className="shapes"
          variants={{
            rest: { opacity: 0 },
            hover: { opacity: 1 }
          }}
        >
          <div className="pink blush" />
          <div className="blue blush" />
          <div className="container">
            <Suspense fallback={null}>
              <Shapes
                isHover={isMailHover}
                isPress={isPress}
                mouseX={mouseX}
                mouseY={mouseY}
              />
            </Suspense>
          </div>
        </motion.div>
        <motion.div
          variants={{ hover: { scale: 0.85 }, press: { scale: 1.1 } }}
          className="label"
        >
          {MailText}
        </motion.div>
      </motion.button>
      <motion.button
        ref={ref}
        initial={false}
        animate={isDiscordHover ? "hover" : "rest"}
        whileTap="press"
        variants={{
          rest: { scale: 1 },
          hover: { scale: 1.5 },
          press: { scale: 1.4 }
        }}
        onHoverStart={() => {
          resetMousePosition();
          setIsDiscordHover(true);
          setDiscordText("@solymos");
        }}
        onHoverEnd={() => {
          resetMousePosition();
          setIsDiscordHover(false);
          setDiscordText("Discord");
        }}
        onTapStart={() => setIsPress(true)}
        onTap={() => setIsPress(false)}
        onTapCancel={() => setIsPress(false)}
        onPointerMove={(e) => {
          mouseX.set(e.clientX - bounds.x - bounds.width / 2);
          mouseY.set(e.clientY - bounds.y - bounds.height / 2);
        }}
      >
        <motion.div
          className="shapes"
          variants={{
            rest: { opacity: 0 },
            hover: { opacity: 1 }
          }}
        >
          <div className="pink blush" />
          <div className="blue blush" />
          <div className="container">
            <Suspense fallback={null}>
              <Shapes
                isHover={isDiscordHover}
                isPress={isPress}
                mouseX={mouseX}
                mouseY={mouseY}
              />
            </Suspense>
          </div>
        </motion.div>
        <motion.div
          variants={{ hover: { scale: 0.85 }, press: { scale: 1.1 } }}
          className="label"
        >
          {DiscordText}
        </motion.div>
      </motion.button>
    </MotionConfig>
    </Page>
    
  );
}

export default Contacts