import React , { useState } from 'react';
import { styled } from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';

const Page = styled.div`
    width: 100vw;
    height: auto;
    scroll-snap-align: start;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 2rem;
    box-sizing: border-box;
`

const Card = styled(motion.div)`
    background-color: ${ props => props.theme.colors.secondary };;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    margin: 10px;
    padding: 20px;
    cursor: pointer;
    width: 45%;
    
    @media (max-width: 1024px) {
		width: 100%;
	}
	
	&:hover {
    background-color : ${ props => props.theme.colors.tertiary };;
  }
`;

const ExpandedCard = styled(motion.div)`
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 40px;
  z-index: 20;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  max-width: 600px;
  height: 80%;
  overflow-y: auto;
`;

const Title = styled.h1`
  color : ${ props => props.theme.colors.primary };
	text-decoration: underline;
	font-size: clamp(2rem, 3.5vw, 3rem); 
	font-family: 'RethinkSans', 'Courier New', Courier, monospace;
`

const items = [
  { id: 1, title: "Slydle", subtitle: "React, FastAPI, MongoDB", description: "Un petit jeu communautaire où le but est de trouver un des joueurs de Solary", img: "", link : "https://slydle.fr" },
  { id: 2, title: "Solary Extension", subtitle: "React", description: "Extension permetant de voir qui sont les streameurs de Solary actuellement en live", img: "", link : "https://github.com/Solymnos/Solary_Extension" },
  { id: 3, title: "SolaryApp", subtitle: "React, React Native, MongoDB, NodeJs", description: "Application permettant d'avoir une multitude d'informations sur les équipes de solary, disponible sur PC et Mobile, avec un site de management." , img: "", link : "https://github.com/Solymnos/solary_app"},
  { id: 4, title: "Portfolio", subtitle: "React, Framer Motion", description: "Le site que vous regardez actuellement :)", img: "", link : "https://github.com/Solymnos/portfolio"  },
  { id: 5, title: "STUC", subtitle: "React", description: "Site permettant de suivre la compétition STUC",img: "", link : "https://slystuc.fr/" },
  { id: 6, title: "Rush Saison 14", subtitle: "React, Mongo, RiotAPI", description: "Développement pour Night Pinguins, création d'un site de suivis des différents joueurs pour le début de la saison 14 de LoL", img: "", link : "https://github.com/Solymnos/rush_s14" },
];

const Container = styled.div`
	display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    @media (min-width: 768px) {
      flex-direction: row;
      justify-content: space-between;
    }
`

const Projects = () => 
{
    const [selectedId, setSelectedId] = useState(null);
    return (
    <Page>
      <Title>Projets</Title>
      <Container>
	    {items.map(item => (
          <Card key={item.id} layoutId={item.id} onClick={() => window.open(item.link, '_blank')}>
            <motion.h5>{item.subtitle}</motion.h5>
            <motion.h2>{item.title}</motion.h2>
            <motion.text>{item.description}</motion.text>
          </Card>
        ))}
      </Container>
    </Page>
  )
}

export default Projects

/*{items.map(item => (
        <Card key={item.id} layoutId={item.id} onClick={() => setSelectedId(item.id)}>
          <motion.h5>{item.subtitle}</motion.h5>
          <motion.h2>{item.title}</motion.h2>
        </Card>
      ))}

      <AnimatePresence>
        {selectedId && (
          <ExpandedCard layoutId={selectedId} initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
            <motion.h5>{items.find(item => item.id === selectedId).subtitle}</motion.h5>
            <motion.h2>{items.find(item => item.id === selectedId).title}</motion.h2>
            <motion.p>{items.find(item => item.id === selectedId).content}</motion.p>
            <motion.button onClick={() => setSelectedId(null)}>Close</motion.button>
          </ExpandedCard>
        )}
      </AnimatePresence>*/