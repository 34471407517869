import React , { useState , useRef, useEffect } from 'react';
import { styled } from 'styled-components';
import { motion, useAnimation } from 'framer-motion';

let CarreerStep = [
	{
		id : 0,
		title : "Lycée Saint Gatien",
		year : "2015",
		details : "Obtiention d'un baccalauréat en Science de l'Ingénieur, mention assez bien",
		logo : "https://images.lanouvellerepublique.fr/image/upload/w_185,h_125/5a9eca60459a45763c8b4588",
		color : '#95F096',
	},
	{
		id : 1,
		title : "DUT Réseaux et Télécommunication",
		year : "2015-2017",
		details : "Obtention de mon DUT à l'université de Blois",
		logo : "https://iut-blois.univ-tours.fr/images/logo.png",
		color : '#FFE78F'
	},
	{
		id : 2,
		title : "BMS37",
		year : "2017",
		details : "Stage pour le compte de mon DUT, effectué au service informatique de BMS37, dévelopepment d'un site web",
		logo : "https://www.bms37.fr/bms37/wp-content/themes/bms/images/logo-bms.png",
		color : '#95F096',
	},
	{
		id : 3,
		title : "EPITECH",
		year : "2017-2022",
		details : "Obtention d'un master en Technologies de l'Information, entre Nantes et Paris",
		logo : "https://upload.wikimedia.org/wikipedia/commons/2/2d/Epitech.png",
		color : '#FFE78F'
	},
	{
		id : 4,
		title : "RESO France",
		year : "2018-2019",
		details : "Stage pour ma seconde année à EPITECH, chez RESO France, chez qui j'ai pu développer des outils internes en Windev",
		logo : "https://crgeoccitanie.web1.optavis.fr/Data/Logos/logo712.png",
		color : '#95F096',
	},
	{
		id : 5,
		title : "IIDRE",
		year : "2021-2023",
		details : "D'abord mon stage de fin d'études, j'ai ensuite poursuivi en CDI chez IIDRE, une entreprise spécialisée dans la géolocalisation de précision, pour qui j'ai pu faire de nombreuses applications clientes",
		logo : "https://www.iidre.com/wp-content/uploads/2024/03/Logo-iidre-Groupe-jllspear.png",
		color : '#FFE78F'
	},
	{
		id : 6,
		title : "Thalès",
		year : "2023-Aujourd'hui",
		details : "Deux missions ont été éffectuées chez Thales, la première consistant au développment et à l'intégration d'une solution sur des cartes (bas niveau) et la seconde sur la création d'interfaces utilisateur",
		logo : "https://chairec3s.wp.imt.fr/files/2017/09/Thales_LOGO_RGB.png",
		color : '#95F096',
	}	
]

const Page = styled.div`
	margin-top: 15rem;
    width: 100vw;
    height: 80%;
    scroll-snap-align: start;
    display: flex;
    padding-right: 12rem;
    box-sizing: border-box;
    flex-direction: row;
    @media (max-width: 1024px) {
        padding-right: 0rem;
        flex-direction: column;
    }
    position: relative;
`

const OverflowImage = styled.img `
	position : absolute;
	top: -19rem;
	left: 2rem;
	max-width: auto;
	max-height: 60rem;
`

const StepContainer = styled.div `
    height: 100%;
    flex: 1;

    position: relative;
    //display: flex;
    //justify-content: center;

    @media (max-width: 1024px) {
        height: auto;
        width: 100vh;
    }
`

const StepContainerSelect = styled.div `
    height: 100%;
    flex: 2;

    position: relative;
    //display: flex;
    //justify-content: center;

    @media (max-width: 1024px) {
        height: auto;
        width: 100vh;
    }
`

const Container = ({ data , setSelectedBubble, selectedBubble}) =>
{
	const containerRef = useRef(null);
	
	return (
		<StepContainer ref={containerRef}>
			<Bubble containerRef={containerRef} data={data} setSelectedBubble={setSelectedBubble} selectedBubble={selectedBubble}/>
		</StepContainer>
	)
}

const ContainerSelect = ({ data , setSelectedBubble , selectedBubble}) =>
	{
		const containerRef = useRef(null);
		
		return (
			<StepContainerSelect ref={containerRef}>
				<BubbleSelect containerRef={containerRef} data={data} setSelectedBubble={setSelectedBubble} selectedBubble={selectedBubble}/>
			</StepContainerSelect>
		)
	}

const Bubble = ({ data, containerRef, setSelectedBubble, selectedBubble }) => 
{
	const bubbleRef = useRef(null);
	const [ position, setPosition] = useState({ x: 0, y: 0});
	
	
	useEffect(() => {
		if (bubbleRef.current && containerRef.current) {
			const bubbleSize = bubbleRef.current.getBoundingClientRect();
			const containerSize = containerRef.current.getBoundingClientRect();
			
			const maxX = containerSize.width - bubbleSize.width;
			const maxY = containerSize.height - bubbleSize.height;
			
			const randomX = Math.random() * maxX;
			const randomY = Math.random() * maxY;
			
			setPosition({ x: randomX, y : randomY })

		}
	}, [bubbleRef, containerRef])
	
	const controls = useAnimation();
	const [isDragging, setDragging] = useState(false);
  
	const handleDragStart = () => setDragging(true);
	const handleDragEnd = (event, info) => {
	  setDragging(false);
	  
	};
  
	const handleClick = () => 
	{
		console.log("s" + selectedBubble)
		console.log("d" + data.id);
		if (selectedBubble == data.id)
		{
			setSelectedBubble(null);
		} else {
			setSelectedBubble(data.id);
		}
	}
    
    useEffect(() => {
        if (!isDragging)
        {
            controls.start({ x: position.x, y : position.y });
        }
    }, [isDragging])
    
    useEffect(() => {
        controls.start({ x: position.x, y: position.y });
    }, [controls, position]);
  
	return (
	  <motion.div
	    ref={bubbleRef}
		drag
		dragConstraints={{ left: 0, right: 800, top: 0, bottom: 600 }}
		onDragStart={handleDragStart}
		onDragEnd={handleDragEnd}
		onClick={handleClick}
		animate={controls}
		initial={{ x : position.x, y : position.y }}
		whileTap={{ scale: 1.2 }}
		style={{
		  width: '80%',
		  aspectRatio : 1/1,
		  borderRadius: '50%',
		  display: 'flex',
		  alignItems: 'center',
		  justifyContent: 'center',
		  cursor: 'pointer',
		  position: 'absolute',
		  userSelect: 'none',
		  zIndex : 10,
		  border: '22px solid',
		  borderColor : data.color,
		  flexDirection : 'column',
		  backgroundColor : '#FDBAFF'
		}}
	  >
	    <BubbleYear>{data.year}</BubbleYear>
	    <BubbleLogo src={data.logo} />
		<BubbleTitle>{data.title}</BubbleTitle>
	  </motion.div>
	);
};

const BubbleSelect = ({ data, containerRef, setSelectedBubble, selectedBubble }) => 
{
	const bubbleRef = useRef(null);
	const [ position, setPosition] = useState({ x: 0, y: 0});
	
	
	useEffect(() => {
		if (bubbleRef.current && containerRef.current) {
			const bubbleSize = bubbleRef.current.getBoundingClientRect();
			const containerSize = containerRef.current.getBoundingClientRect();
			
			const maxX = containerSize.width - bubbleSize.width;
			const maxY = containerSize.height - bubbleSize.height;
			
			const randomX = Math.random() * maxX;
			const randomY = Math.random() * maxY;
			
			setPosition({ x: randomX, y : randomY })

		}
	}, [bubbleRef, containerRef])
	
	const controls = useAnimation();
	const [isDragging, setDragging] = useState(false);
  
	const handleDragStart = () => setDragging(true);
	const handleDragEnd = (event, info) => {
	  setDragging(false);
	  
	};
  
	const handleClick = () => 
	{
		console.log("s" + selectedBubble)
		console.log("d" + data.id);
		if (selectedBubble == data.id)
		{
			setSelectedBubble(null);
		} else {
			setSelectedBubble(data.id);
		}
	}
	
	useEffect(() => {
		if (!isDragging)
		{
			controls.start({ x: position.x, y : position.y });
		}
	}, [isDragging])
	
	useEffect(() => {
		controls.start({ x: position.x, y: position.y });
	}, [controls, position]);
  
	return (
	  <motion.div
		ref={bubbleRef}
		drag
		dragConstraints={{ left: 0, right: 800, top: 0, bottom: 600 }}
		onDragStart={handleDragStart}
		onDragEnd={handleDragEnd}
		onClick={handleClick}
		animate={controls}
		initial={{ x : position.x, y : position.y }}
		whileTap={{ scale: 1.2 }}
		style={{
		  width: '80%',
		  aspectRatio : 1/1,
		  borderRadius: '50%',
		  display: 'flex',
		  alignItems: 'center',
		  justifyContent: 'center',
		  cursor: 'pointer',
		  position: 'absolute',
		  userSelect: 'none',
		  zIndex : 10,
		  border: '22px solid',
		  borderColor : data.color,
		  flexDirection : 'column',
		  backgroundColor : '#FDBAFF'
		}}
	  >
		<BubbleYear>{data.year}</BubbleYear>
		<BubbleLogo src={data.logo} />
		<BubbleDetail>{data.details}</BubbleDetail>
		<BubbleTitle>{data.title}</BubbleTitle>
	  </motion.div>
	);
};

const BubbleYear = styled.text`
	font-size: clamp(0.5rem, 1vw, 1.5rem);
	color: black;
	font-weight: bold;
	font-family: 'RethinkSans', 'Courier New', Courier, monospace;
`

const BubbleTitle = styled.text`
	font-size: clamp(1rem, 2vw, 1.5rem);
	text-align: center;
	font-weight: bold;
	font-family: 'RethinkSans', 'Courier New', Courier, monospace;
`

const BubbleLogo = styled.img`
	max-width: 10rem;
	max-height: 10rem;
`

const BubbleDetail = styled.text`
	font-size: clamp(1rem, 1.5vw, 1.5rem);
	color : black;
	padding: 1rem;
	text-align: center;
	font-family: 'NectoMono', 'Courier New', Courier, monospace;
`

const Career = () => 
{	
	const [selectedBubble, setSelectedBubble ] = useState(null);

    return (
        <Page>
            <OverflowImage src='/img/Coeur.png'/>
            {
                CarreerStep.map((step, index) => (
                    step.id === selectedBubble ?
                    <ContainerSelect key={step.id} data={step} setSelectedBubble={setSelectedBubble} selectedBubble={selectedBubble}/> :
                    <Container key={step.id} data={step} setSelectedBubble={setSelectedBubble} selectedBubble={selectedBubble}/>
                ))    
            }
        </Page>
        
    )
}

export default Career