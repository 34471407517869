import React , { useRef } from 'react';
import { styled } from 'styled-components';
import { ReactComponent as AnimatedSVG } from '../assets/img/AnimatedSVG.svg';
import { motion, useElementScroll, useSpring } from 'framer-motion';

const Page = styled.div`
    width: 100vw;
    height: 90vh;
    scroll-snap-align: start;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 12rem;
    box-sizing: border-box;
    @media (max-width: 1024px) {
        padding-right: 0rem;
    }
`

const PageContent = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    @media (max-width: 1024px) {
        flex-direction: column-reverse;
    }
    position: relative;
`

const LeftDecoration = styled.div`
	background-color: ${ props => props.theme.colors.secondary};
	width: 40%;
	min-width: 20rem;
	height: 6rem;
	position: absolute;
	padding: 1rem;
	top: 0;
	left: 0;
	z-index: 0;
	box-sizing: border-box;
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 1rem;
	@media (max-width: 1024px) {
        height: 3rem;
        padding: 0.5rem;
    }
`

const NumberImg = styled.img`
    max-width: 4rem;
    max-height: 4rem;
    @media (max-width: 1024px) {
        max-height: 2rem;
    }
`

const Title = styled.h1`
    color: ${ props => props.theme.colors.primary };
	font-size: clamp(3rem, 5rem, 7rem);
	font-family: 'RethinkSans', 'Courier New', Courier, monospace;
	margin: 0;
	@media (max-width: 1024px) {
        font-size: clamp(2rem, 3rem, 4rem);
    }
`

const LeftContent = styled.div`
	width: 50%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	box-sizing: border-box;
	align-items: center;
	padding-top: 11rem;
	padding-left: 5rem;
	padding-right: 5rem;
	padding-bottom: 5rem;
	@media (max-width: 1024px) {
        width: 100%;
        height: 50%;
        text-align: center;
        padding-top: 3rem;
    }
`

const LeftUnivers = styled.div`
	height: 20%;
	width: 20%;
	position: absolute;
    z-index: 1;
    background-color: ${ props => props.theme.colors.tertiary };
    padding: 1rem;
    border-radius: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border : 2px solid white;
`

const LeftContainer = styled.div`
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	position: relative;
	justify-content: center;
	align-items: center;
`

const SVGContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ResponsiveSVG= styled(AnimatedSVG)`
	width: 100%;
	height: auto;
`;

const AnimatedTextAroundDiv = () => {
	return (
	  <SVGContainer>
		<ResponsiveSVG />
	  </SVGContainer>
	);
  };

const RightContent = styled.div`
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
    padding : 5rem;
    @media (max-width: 1024px) {
		width: 90%;
		height: 50%;
		padding : 5rem;	
	}
`

const RightData = styled.div`
	width: 100%;
	height: 100%;
	border:  ${ props => props.theme.colors.tertiary } solid 0.5rem;
	padding: 2rem;
	overflow-y: auto;
	scrollbar-width: large;
    scrollbar-color: ${ props => props.theme.colors.primary } ${ props => props.theme.colors.secondary };

    &::-webkit-scrollbar {
        width: 8px;
    }

    &::-webkit-scrollbar-track {
        background: ${ props => props.theme.colors.secondary };
        border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #29d;
        border-radius: 10px;
        border: 3px solid ${ props => props.theme.colors.secondary };
    }
`

const RightTitle = styled.h1`
	color : ${ props => props.theme.colors.primary };
	text-decoration: underline;
	font-size: clamp(2rem, 3.5vw, 3rem); 
	font-family: 'RethinkSans', 'Courier New', Courier, monospace;
`

const RightText = styled.text`
	font-family: 'NectoMono', 'Courier New', Courier, monospace;
	color: black;
	font-size: clamp(1rem, 1vw, 2rem); 
	
`

const ProgressBar = styled(motion.div)`
    top: 0;
    left: 0;
    right: 0;
    height: 0.5rem;
    background: ${ props => props.theme.colors.primary };
    transform-origin: 0%;
    z-index: 50;
    width: 100%;
`;

const Profile = () => 
{
	const rightDataRef = useRef(null);
	const { scrollYProgress } = useElementScroll(rightDataRef);
	const scaleX = useSpring(scrollYProgress, {
		stiffness: 100,
		damping: 30,
		restDelta: 0.001
	});
    
    return (
        <Page>
            <PageContent>
                <LeftDecoration>
                  <NumberImg src='/img/0 Pastel.png'/>
                  <Title>Profil</Title>
                </LeftDecoration>
                <LeftContent>
                    <LeftContainer>
                        <AnimatedTextAroundDiv />
                        <LeftUnivers />
                    </LeftContainer>  
                </LeftContent>
                <RightContent>
                    <ProgressBar style={{ scaleX }} />
                    <RightData ref={rightDataRef}>
                        <RightTitle>Profil</RightTitle>
                        <RightText>
							<p>{"Bonjour, voici un petit article pour vous donner un aperçu de mon histoire et de mes hobbies. Tout d'abord, sachez que je serais heureux de vous parler tout ça en personne."}</p>
							<p>{"Pendant la préparation de mon bac S en Science de l'Ingénieur, j'ai commencé à découvrir le développement informatique en utilisant des langages très simples, mais qui m'ont permis de faire mes premiers pas dans ce domaine. Après le baccalauréat avec mention, je savais que je voulais continuer dans la voie de l'informatique, mais je ne savais pas dans quelle spécialité je souhaitais m'orienter précisément, j'ai donc choisi un DUT général, celui de Réseaux et Télécommunication de Blois. Pendant les deux années du DUT, j'ai pu découvrir de nouvelles langues (Python et C), mais malheureusement, la plupart du cursus ne se focalisaient pas sur le développement, mais sur d'autres matières pour lesquelles j'avais moins d'appétences. Donc, après l'obtention de mon DUT, j'ai intégré EPITECH. Cette école, où le code est au centre de la formation, est l'endroit où j'ai pu acquérir mes compétences et mon envie de progresser. C'était une excellente expérience, car j'ai eu l'occasion d'apprendre beaucoup de langages (en particulier C et C++, langages indispensables à EPITECH), de techniques d'organisation, de travail en équipe et de management.\n\n"}</p>
							<p>{"Au sein d'EPITECH, j'ai également eu l'occasion de participer à de nombreuses expériences professionnelles, notamment un stage de 6 mois chez RESO France, une société spécialisée dans le placement d'employés dans les établissements de restauration. Pendant ce stage, j'ai occupé le rôle de maintenance et d'amélioration des logiciels et services utilisés en interne au sein du service informatique, y compris le développement d'un logiciel de comptabilité en Windev. Une autre spécificité d'EPITECH, est le projet final, qui s'étend sur les 3 dernières années de la formation et pendant laquelle, en équipe, nous avions pour objectif de développer une solution d'aide aux agents de sécurité. Ce projet m'a permis de participer à la création d'une application mobile de A à Z, comprenant, au vu du projet, de nombreuses questions de sécurité. Pendant ma dernière année à Epitech, j'ai pu faire un stage d'un an chez IIDRE (où je poursuivrai ma carrière après mes études), une entreprise spécialisée dans la création de solutions autour de technologies de géolocalisation précise.\n\n"}</p>
							<p>{"J'ai eu l'occasion de travailler sur plusieurs technologies chez IIDRE, car le type de projets sur lesquels j'ai pu travailler était très varié et qu'il fallait s'adapter aux différents besoins des clients. On pourra citer en exemple une application web en VueJS, une application mobile en Java et un exécutable Windows en Qt. Malheureusement, après une réduction d'effectifs, j'ai dû quitter mon poste, mais cela m'a ouvert d'autres portes. Ainsi, j'ai eu l'occasion de pouvoir travailler pour Thalès. J'ai saisi cette occasion pour découvrir le fonctionnement d'une entreprise aussi grande (je n'avais connu que des petites et moyennes entreprises jusqu'à présent.).  À Thalès, j'ai eu l'occasion de développer deux projets : un premier, en R&T de bas niveau, où j'ai eu l'occasion de réaliser l'intégration d'un module de parsing de trames sur des cartes de type T2080, et un deuxième, sur laquelle je travaille actuellement, où je réalise des interfaces utilisateur.\n\n"}</p>
							<p>{"Côté passion, j'ai passé beaucoup de temps au rugby, que j'ai commencé à pratiquer dès mon plus jeune âge, et que j'aime encore, même si aujourd'hui, j'ai un peu délaissé ce sport. Néanmoins, j'essaie toujours de maintenir une discipline sportive en pratiquant la musculation. Je suis également un fan de jeu vidéo, en particulier du côté compétitif. De nombreux projets que j'ai réalisés personnellement sont axés sur cette passion pour l'esport, en particulier autour de l'équipe de Solary, qui me tient à cœur. Plus traditionnellement, j'aime aussi la musique et le cinéma et j'aime glisser des références à ces domaines dans mes différents projets !\n\n"}</p>
							<p>{"Dans le futur, j'aimerais pouvoir travailler dans le secteur que j'adore, celui du jeu vidéo. Je suis conscient du niveau nécessaire pour occuper ce type de poste et c'est pour cela que j'effectue de l'autoformation et de la veille technologique pour parvenir à un niveau suffisant pour pouvoir postuler pour ce poste. Aujourd'hui, j'aspire à occuper un poste dans le domaine du fullstack, car c'est ce que je pratique actuellement sur la majorité de mes projets personnels et c'est ce que j'aime utiliser, notamment avec mes technologies de prédilections telles que React pour le front et NodeJS/FastApi pour le back.  L'idéal étant évidemment dans une entreprise déjà dans le domaine, afin d'avoir déjà un premier pied dans la spécialité, afin d'entamer mon ascension vers mon but final.\n\n"}</p>
							<p>{"Je vous remercie, qui que vous soyez, pour avoir lu jusqu'à là, n'hésitez pas à me contacter si vous avez des questions supplémentaires, j'y répondrais avec plaisir.\n\n"}</p>
						</RightText>
                    </RightData>
                </RightContent>
            </PageContent>
        </Page>
    )
}

export default Profile