import React from 'react';
import { ThemeProvider, styled } from 'styled-components';
import { theme } from './theme';

import './App.css';

import Home from './pages/Home';
import Career from './pages/Career';
import Competences from './pages/Competences';
import Contacts from './pages/Contacts';
import Profile from './pages/Profile';
import Projects from './pages/Projects';
import Header from './components/global/Header';

const Background = styled.div`
  height: 100vh;
  width: 100vw;
  background-color: ${ props => props.theme.colors.background };
  color : ${ props => props.theme.colors.primary };
  //scroll-snap-type: y mandatory;
  //scroll-behavior: smooth;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  
  &::-webkit-scrollbar {
    display: none;
  }
`;

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Background>
        <Header/>
        <Home />
        <Profile />
        <Career />
        <Competences />
        <Projects />
        <Contacts />
      </Background>
    </ThemeProvider>
  )
  
}

export default App;
