import React from 'react';
import { styled } from 'styled-components';

const Page = styled.div`
    padding-top: 4rem;
    width: 100vw;
    height: 100vh;
    scroll-snap-align: start;
    display: flex;
    justify-content: center;
    text-align: center;
    flex-direction: column;
`

const Title = styled.h1`
  color : ${ props => props.theme.colors.primary };
	text-decoration: underline;
	font-size: clamp(2rem, 3.5vw, 3rem); 
	font-family: 'RethinkSans', 'Courier New', Courier, monospace;
`

const Competence = styled.text`
  color : ${ props => props.theme.colors.text };
  font-size: clamp(1rem, 1.5vw, 2rem); 
  font-family: 'NectoMono', 'Courier New', Courier, monospace;

  &:hover {
    font-size: clamp(1.5rem, 2.5vw, 2.5rem);
    //color : ${ props => props.theme.colors.tertiary };
    font-weight: bold;
    text-decoration: underline;
    text-decoration-color: ${ props => props.theme.colors.tertiary };
  }
`

const CompetencesList = ["C", "C++", "Javascript", "React", "Python", "NodeJS", "HTML/CSS", "Java", "Kotlin", "VueJS", "Git", "FastAPI", "TypeScript", "Français Natif", "Anglais courant", "Organisation Agile", "MongoDB", "SQL", "Plus encore ..."]



const Competences = () => {
  return (
    <Page>
      <Title>Compétences</Title>
        {CompetencesList.map((competence) =>
        (
          <Competence>{competence}</Competence>
        ))}
    </Page>
  )
}

export default Competences