import React from 'react';
import { styled } from 'styled-components';

const Container = styled.div`
    height: 8rem;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: ${ props => props.theme.colors.secondary };
    display: flex;
    @media (max-width: 1024px) {
        height: 6rem;
        justify-content: center;
    }
    z-index: 100;
`

const Logo = styled.img`
    height: 6rem;
    margin: 1rem;
    @media (max-width: 1024px) {
        height: 4rem;
    }
`

const Header = () => {
  return (
    <Container>
        <Logo src='/img/Logo Pastel.png' />
    </Container>
  )
}

export default Header