import React from 'react'
import { styled } from 'styled-components'
//import CV from '/CV.pdf';

const Page = styled.div`
    width: 100%;
    height: 100%;
    //scroll-snap-align: start;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 8rem;
    padding-right: 12rem;
    box-sizing: border-box;
    @media (max-width: 1024px) {
        padding-top: 6rem;
        padding-right: 0rem;
    }
`

const PageContent = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    @media (max-width: 1024px) {
        flex-direction: column-reverse;
    }
    position: relative;
`

const LeftContent = styled.div`
	width: 50%;
	height: 100%;
	padding: 2.5rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
	box-sizing: border-box;
	
	@media (max-width: 1024px) {
        width: 100%;
        height: 50%;
        padding: 2.5;
        text-align: center;
    }
`

const RightContent = styled.div`
	width: 50%;
	height: 100%;
	padding: 2.5rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
	box-sizing: border-box;
	@media (max-width: 1024px) {
        width: 100%;
        height: 50%;
    }
`

const LeftTitle = styled.h1`
	color: ${ props => props.theme.colors.primary };
	font-size: clamp(3rem, 5rem, 7rem);
	font-family: 'RethinkSans', 'Courier New', Courier, monospace;
	@media (max-width: 1024px) {
        font-size: clamp(2rem, 3rem, 4rem);
    }
`

const LeftText = styled.text`
	color : ${ props => props.theme.colors.text };
	font-size: clamp(0.5rem, 2rem, 2.5rem);
	font-family: 'NectoMono' ,'Courier New', Courier, monospace;
	z-index: 10;
	@media (max-width: 1024px) {
        font-size: clamp(1rem, 1rem, 1.5rem);    
    }
`

const RightAnimation = styled.div`
	background-color: ${ props => props.theme.colors.tertiary };
	height: 100%;
	width: 100%;
	display: flex;
	color : ${ props => props.theme.colors.text };
	align-items: center;
	justify-content: center;
	font-size: clamp(3rem, 3rem, 7rem);
	font-family: 'RethinkSans', 'Courier New', Courier, monospace;
	border-radius: 4rem;
`

const DownloadLink = styled.a`
	height: 100%;
	width: 100%;
`

const LeftDecoration = styled.div`
	background-color: ${ props => props.theme.colors.secondary};
	width: 40%;
	min-width: 20rem;
	height: 6rem;
	position: absolute;
	bottom: 0;
	left: 0;
	z-index: 0;
	@media (max-width: 1024px) {
        height: 3rem;
    }
`

const Home = () => {
  return (
    <Page> 
        <PageContent>
            <LeftContent>
                <LeftTitle>Carl Ameline</LeftTitle>
                <LeftText>Ingénieur Fullstack à la recherche de nouvelles opportunités, mes domaines de prédilections sont le développement web, l’esport et le jeu vidéo.</LeftText>
            </LeftContent>
            <RightContent>
                <DownloadLink href={'/CV.pdf'} download="CV_Carl_Ameline" target='_blank'>
					<RightAnimation>TELECHARGEZ MON CV</RightAnimation>
                </DownloadLink>
            </RightContent>
            <LeftDecoration/>
        </PageContent>
    </Page>
  )
}

export default Home